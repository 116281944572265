<template>
  <div class="BigBox">
    <!-- 标识 -->
    <div style="
          width: 80%;
          height: 20px;
          margin: 2% auto;
          color: rgba(68, 68, 68, 1);
          font-size: 22px;
          margin-top: 3%;
        ">
      <span>靖港古镇</span>
      <img src="../../assets/VRVisit/ShadowPlay/arrow.png" style="height: 14px;margin: 0 1%" />
      <span>历史建筑</span>
      <img src="../../assets/VRVisit/ShadowPlay/arrow.png" style="height:14px;margin: 0 1%" />
      <span>靖港观音殿</span>
    </div>
    <div class="MainContents">
      <!-- 主图 -->
      <div class="MainPicture">
        <!-- <img :src=BuildingsParticularsList?.imgs[ImgIndex]?.resourceUrl alt=""> -->
        <img :src=imgUrl alt="">

        <!-- 音频播放器 -->
        <div class="Mp3Box">
          <audio controls="controls" loop="loop">
            <source src="audio/极乐净土.mp3" type="audio/mp3">
          </audio>
        </div>
      </div>
      <!-- 选择图片 -->
      <div class="MainPictureList">
        <img v-for="(imgs, index) in BuildingsParticularsList.imgs" :key="index" :src="imgs.resourceUrl"
          @click="TabImg(index)" alt="图片加载失败">
      </div>
    </div>
    <div class="ParticularsBox">
      <div style="font-size: 5vh;">【靖港观音殿】</div>
      <div class="ParticularsCon">
        <div class="ParticularsConLeft">
          <div>所在地点：<text>保良前街2号</text></div>
          <div>建筑类型：<text>宗教建筑</text></div>
          <div>始建年代：<text>清乾隆正六年(1728)</text></div>
        </div>
        <div class="ParticularsConRight">
          <div>占地面积：<text>260m²</text></div>
          <div>结构形式：<text>砖木石结构</text></div>
        </div>
      </div>
      <div class="SourceValue">
        <div>
          景源价值：<text>对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值</text>
        </div>
      </div>
      <div class="ParticularsBoxTwo">
        <div class="ParticularsBoxTwoImg">
          <img src="" alt="">
        </div>
        <div class="ParticularsBoxTwoText">
          对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值对研究长沙地区清代寺庙建筑具有一定的历史价值和艺术价值
        </div>
      </div>
    </div>
    <div class="AncientBuilding">
      <img src="../../assets/VRVisit/HistoricBuildings/Knowledge1.png" alt="">
    </div>
    <div class="AncientBuildingConBox">
      <div class="AncientBuildingCon">
        <div class="AncientBuildingConImg">
          <!-- <div><img src="../../assets/AncientTown/ChengZhouMap.png" alt=""></div> -->
        </div>
        <div class="AncientBuildingConText"></div>
      </div>
      <div class="AncientBuildingCon">
        <div class="AncientBuildingConText"></div>
        <div class="AncientBuildingConImgTwo">
          <!-- <div><img src="../../assets/AncientTown/ChengZhouMap.png" alt=""></div> -->
        </div>
      </div>
    </div>
    <Navigation_Buttons></Navigation_Buttons>
    <Bottom_Bar></Bottom_Bar>
  </div>
</template>

<script>
import $ from "jquery";
import Navigation_Buttons from "../Public/Navigation_Buttons";
import Bottom_Bar from "../Public/Bottom_Bar";
export default {
  components: {
    Navigation_Buttons,
    Bottom_Bar,
  },
  data() {
    return {
      imgUrl: '',
      HistoricID: 33,
      BuildingsParticularsList: '',
      ImgIndex: 0
    };
  },
  created() {
    this.GetBuildingsParticulars()
    //添加滚动监听事件
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener('scroll', this.windowScrollListener);
  },
  methods: {
    // 获取页面数据
    GetBuildingsParticulars() {
      var axios = require("axios");
      var data = JSON.stringify({});
      var config = {
        method: "get",
        url: this.$Schttp + "/vtp/app/contentPublish/getBuildiingDetail/" + this.HistoricID,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          this.BuildingsParticularsList = res.data.data
          this.imgUrl = this.BuildingsParticularsList.imgs[0].resourceUrl
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 更换图片
    TabImg(index) {
      this.imgUrl = this.BuildingsParticularsList.imgs[index].resourceUrl
    },
    // 监听窗口移动到哪个位置触发底部图片显示
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 1150) {
        $(".AncientBuildingConImg div").animate({ left: 0 }, 2000);
      }
      if (scrollTop >= 1250) {
        $(".AncientBuildingConImgTwo div").animate({ left: 0 }, 2000);
      }
      // if (scrollTop < 1250) {
      //   $(".AncientBuildingConImg div").animate({ left: '-200px' },2000);
      //   $(".AncientBuildingConImgTwo div").animate({ left: "200px" },2000);
      // }
    },
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth
        this.screenWidth = window.screenWidth
      })()
    }
  },
  destroyed() {
    //离开页面时删除该监听
    window.removeEventListener('scroll', this.windowScrollListener)
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.BigBox {
  width: 100%;
  overflow: hidden;

  .MainContents {
    width: 85%;
    height: 600px;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .MainPicture {
      width: 71%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .MainPictureList {
      width: 25%;
      height: 100%;
      overflow-y: scroll;

      img {
        width: 100%;
        height: 30%;
        margin-bottom: 1vh;
        cursor: pointer;
      }
    }
  }

  .Mp3Box {
    position: relative;
    top: -60px;
    display: flex;
    flex-direction: row-reverse
  }

  .ParticularsBox {
    width: 85%;
    min-width: 1200px;
    height: 700px;
    max-height: 800px;
    margin: 3vh auto;

    text {
      color: #7A7A7A;
    }

    .ParticularsCon {
      width: 60%;
      display: flex;
      margin-left: 5vh;
      justify-content: space-between;

      .ParticularsConLeft {
        width: 70%;

        div {
          margin: 2vh 0;
        }
      }

      .ParticularsConRight {
        width: 30%;

        div {
          margin: 2vh 0;
        }
      }
    }

    .SourceValue {
      width: 60%;
      margin-left: 5vh;
      margin-bottom: 3vh;
    }
  }

  .ParticularsBoxTwo {
    width: 95%;
    height: 400px;
    margin-left: 4vh;
    display: flex;
    justify-content: space-between;

    .ParticularsBoxTwoImg {
      width: 60%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ParticularsBoxTwoText {
      width: 38%;
      height: 100%;
      overflow-y: scroll; //超出隐藏

    }
  }

  .AncientBuilding {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      margin: 0 auto;
    }
  }

  .AncientBuildingConBox {
    width: 85%;
    min-width: 1200px;
    height: 600px;
    margin: 3vh auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .AncientBuildingCon {
      height: 48%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .AncientBuildingConImg {
        width: 26%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          position: relative;
          left: -200px;
          width: 80%;
          height: 90%;
          border-radius: 93% 83% 162% 68%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .AncientBuildingConImgTwo {
        width: 26%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          position: relative;
          left: 200px;
          width: 90%;
          height: 90%;
          border-radius: 25%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .AncientBuildingConText {
        width: 72%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow-y: scroll;
      }
    }
  }

  // 滚动条设置
  @remvw: 1920 /100vw;

  ::-webkit-scrollbar {
    width: 7 / @remvw;
    /*对垂直流动条有效*/
    height: 7 / @remvw;
    /*对水平流动条有效*/
  }

  /*定义外层滚动槽*/
  ::-webkit-scrollbar-track-piece {
    border-radius: 6 / @remvw;
  }

  /*定义内层 滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 6 / @remvw;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
    opacity: 0.3;
  }

  /deep/ .el-dialog__body::-webkit-scrollbar {
    /* 隐藏默认的滚动条 */
    -webkit-appearance: none;
  }

  /*定义两端按钮的样式*/
  ::-webkit-scrollbar-button {
    display: none;
  }

  /*定义边角样式*/
  ::-webkit-scrollbar-corner {
    display: none;
  }
}</style>
